<template>
<div>
    <!-- ETF Table -->
    <v-data-table
      :headers="headers"
      :items="etfs"
      :hide-default-footer=true
      mobile-breakpoint="0"
      class="elevation-1"  
      no-data-text="자산이 없습니다."     
    >      
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
      :color="snackColor"          
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

</div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: "EtfTable",
  data() {
    return {
      headers: [
        { text: '이름', align: 'start', sortable: false, value: 'name' },
        { text: '티커', value: 'symbol', align: 'center', sortable: false },        
        { text: '배분율(%)', value: 'ratio', align: 'center', sortable: false },
        { text: '자산분류', value: 'category', align: 'center', sortable: false },
      ],
      sumRatio: 100,
      snackbar: false,
      snackText: null,
      snackColor: null,
      vertical: true,
    }
  },

  computed: {
    ...mapState(['debug', 'etfs'])
  },

  created() {    
  },

  methods: { 
    ...mapMutations(['setEtfs']),

    saveEtf() {
      //this.sumRatio = this.etfs.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
      //if (this.sumRatio !== 100) {
      //    this.snackbar = true
      //    this.snackColor = 'red lighten-1'
      //    this.snackText = '배분율 합계는 100%로 설정하세요.'
      //} else {
        this.setEtfs({'etfs': this.etfs});
      //}
    },
  }

}
</script>
