<template>
  <div class="assets">
    <v-alert
      border="top"
      color="red lighten-2"
      dark
    >
      현재는 미국시장만 데이터 제공 중임 (데이터소스: 야후 파이넨스)
    </v-alert>

    <span class="text-h5 text--primary d-flex pa-2 font-weight-bold">
      글로벌 자산 검색
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-icon class="pl-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
      </template>
        <BacktestHelp
          :he01 = "true"
        />
      </v-tooltip>
    </span>
    <br>
    <v-toolbar
      flat
    >
      <v-toolbar-title>ETF 목록</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <template>
        <v-select
          :items="countries"
          label="국가"
          item-text="country"
          item-value="country"
          v-model="selectedCountry"
          @change="get_etfs()"
        ></v-select>
      </template>
  </v-toolbar>

  <v-card>
    <!-- chip -->
    <v-container class="py-0">
      <v-row
        align="center"
        justify="start"
      >
        <v-col
          v-for="(selection, i) in selected"
          :key="selection.symbol"
          class="shrink"
        >
          <v-chip
            :disabled="loading"
            close
            @click:close="selected.splice(i, 1)"
          >
            {{ selection.name }} ({{ selection.symbol }})
          </v-chip>
        </v-col>
      </v-row>      
    </v-container>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-container fluid>
      <v-row align="end" justify="space-between">
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-subheader>* 포트폴리오에 추가할 자산을 선택하세요. </v-subheader>  
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-btn
            v-if="selected.length"
            text
            color="primary"
            small 
            class="font-weight-bold"
            @click="put_ports"
          >
          <v-icon left> mdi-plus </v-icon>
            나의 ETF 포트폴리오에 추가
          </v-btn>          
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="assets"
      :search="search"
      item-key="symbol"
      show-select      
      class="elevation-1"
      :loading="loadTable"
      loading-text="로딩중... 잠시만 기다려 주세요."      
    > 
    <template v-slot:top>
      <!-- 세부정보 Dialog -->
      <v-dialog 
          v-model="dialogInfo"
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :loading="loading"
      >
        <v-card
          width="400"
        >
          <v-card-title class="text-h6">{{title}}</v-card-title>
          <v-card-actions>
            <v-container>
              <v-row><v-col>
                  <!-- <EtfInfoList /> -->
                  <v-data-table
                    :headers="performance_headers"
                    :items="performances"
                    :items-per-page="100"
                    hide-default-footer
                  ></v-data-table>
              </v-col></v-row>
              <v-row><v-col>
                <v-btn color="blue darken-1" text @click="dialogInfo=false">닫기</v-btn>
              </v-col></v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template> 
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="get_info(item)"> mdi-format-list-numbered </v-icon>
    </template>
    </v-data-table>    
  </v-card> 
  
  
  <!-- 나의 ETF 포트폴리오 추가 Dialog -->
  <v-dialog 
    v-model="dialogEtf" 
    width="auto" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="text-h5" >자산 배분</v-card-title>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-container>
          <v-row><v-col>
            <v-text-field label="제목*" hint="포트폴리오 제목을 입력하세요" persistent-hint v-model="port_title"> 
            {{ port_title }} 
            </v-text-field>        
          </v-col></v-row>
          <v-row><v-col>
              <EtfTable />
          </v-col></v-row>
          </v-container>
          <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="update_etfs()">저장</v-btn>
          <v-btn color="blue darken-1" text @click="dialogEtf=false">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>


</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  // import EtfInfoList from '../components/EtfInfoList.vue';
  import EtfTable from '../components/EtfTable.vue';
  import BacktestHelp from '../components/BacktestHelp.vue';
 

  export default {
    name: "Assets",
    // components: { EtfInfoList, EtfTable, BacktestHelp },
    components: { EtfTable, BacktestHelp },
    data () {
      return {
        dialogInfo: false,
        loadTable: false,
        loading: false,
        dialogEtf: false,

        headers: [
            { text: '이름', value: 'name', align: 'start' },
            { text: '전체이름', value: 'full_name' },
            { text: '티커', value: 'symbol' },
            { text: 'isin', value: 'isin' },
            { text: '통화', value: 'currency' },
            { text: '시장', value: 'stock_exchange' },            
            { text: '실적', value: 'actions', sortable: false, align: 'center' },
        ],
        performance_headers: [
          { text: '항목', value: 'attribute', align: 'start' },
          { text: '값', value: 'value' },
        ],
        search: '',
        assets: [],
        countries: [],
        performances: [],
        selectedCountry: 'united states',
        expanded: [],
        selected: [],
        title: null,
        port_title: null,
        
        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,

        sumRatio: 0,
      }
    },
        
    watch: {
    },

    computed: {
        ...mapState(['debug', 'isLogin', 'isLogout', 'user', 'etfs']) 
    },

    created() {
      if (this.isLogin) this.get_countries();
      else this.$router.push("/");
    },

    methods: {
        ...mapMutations(['setEtfInfo', 'setEtfs']),
        
        get_countries() {

            // yahoo finance는 미국 자산만 보여주기때문에 국가리스트는 미국으로만 한정
            this.countries = ['united states'];
            this.get_etfs(); 
            // var vm = this;
            // const req_data = {'user': vm.user}
            // const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            // axios.post('/api/etf/countries/', req_data, {headers})
            //     .then(function(res) {
            //         vm.countries = res.data;                                      
            //         vm.get_etfs();  
            //     })
            //     .catch(function (err) {
            //       vm.snackbar = true;
            //       vm.snackColor = 'error';
            //       vm.snackText = err;            
            //     });
        },

        get_etfs() {    
            var vm = this;
            vm.loadTable = true;    
            const req_data = {'country': this.selectedCountry, 'format': 'long'}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            axios.post('/api/etf/etfs/', req_data, {headers})
                .then(function(res) {
                    vm.assets = res.data;   
                    vm.loadTable = false;
                })
                .catch(function (err) {
                    vm.loadTable = false;    
                    vm.snackbar = true;
                    vm.snackColor = 'error';
                    vm.snackText = err;
                });
        },

        get_info(item) {
          this.loading = true;
          this.title = item.name;
          this.info = [];
          var vm = this;
          const req_data = {'country': item.country, 'name': item.name, 'symbol': item.symbol}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/etf/info/', req_data, {headers})
              .then(function(res) {
                  vm.performances = res.data;
                  // vm.setEtfInfo({'etf_info': res.data});              
                  vm.dialogInfo = true;    
                  vm.loading = false;        
              })
              .catch(function (err) {
                  vm.loadTable = false;    
                  vm.snackbar = true;
                  vm.snackColor = 'error';
                  vm.snackText = err;
              });        
        },

        put_ports() {
          var etfs = []          
          for (var etf of this.selected) {
            if (etf.country != 'united states') {
              this.snackbar = true;
              this.snackColor = 'error';
              this.snackText = '현재는 미국 ETF만 포트 추가가 가능합니다.';
              return
            } else {
              etfs.push({'name': etf.name, 'symbol': etf.symbol, 'category': null, 'ratio': null});  
            }
          }
          this.setEtfs({'etfs': etfs});
          this.dialogEtf = true;          
        },

        update_etfs() {
          this.sumRatio = this.etfs.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
          if (!this.port_title) {
              this.snackbar = true;
              this.snackColor = 'error';
              this.snackText = '포트폴리오 제목을 입력하세요.';
          } else {          
            if (this.sumRatio !== 100) {
              this.snackbar = true
              this.snackColor = 'red lighten-1'
              this.snackText = '배분율 합계를 100%로 설정하세요.'
            } else {
              var vm = this;
              const req_data = {'user': this.user, 'title': this.port_title, 'etfs': this.etfs}
              const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
              axios.post('/api/etf/port/save/', req_data, {headers})
                  .then(function(res) {
                      vm.dialogEtf = false;    
                      vm.snackbar = true;
                      vm.snackColor = 'success';
                      vm.snackText = '추가되었습니다.';
                      vm.$router.push({ name : 'EtfPort'})
                  })
                  .catch(function (err) {
                      vm.loadTable = false;    
                      vm.snackbar = true;
                      vm.snackColor = 'error';
                      vm.snackText = err;
                  });
            }
          }
        },

    }
  }
</script>


<style>
.assets{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
